:root[data-site='mymoto'] {
  --colour-accent-1-darken: #ccaa00;
  --colour-accent-1-darkest: #665500;
  --colour-accent-1-default: #ffd500;
  --colour-accent-1-disabled: #c1c7cc;
  --colour-accent-1-lighten: #ffdd33;
  --colour-accent-1-lightest: #fffbe5;
  --colour-accent-1-on-accent-1-disabled: #ccaa00;
  --colour-accent-1-on-accent-1: #00344b;
  --colour-accent-2-darken: #ff3333;
  --colour-accent-2-darkest: #ff002b;
  --colour-accent-2-default: #ff9999;
  --colour-accent-2-disabled: #c1c7cc;
  --colour-accent-2-lighten: #ffcccc;
  --colour-accent-2-lightest: #ffe5e6;
  --colour-accent-2-on-accent-2-disabled: #ffcccc;
  --colour-accent-2-on-accent-2: #00344b;
  --colour-action-darken: #000ecc;
  --colour-action-darkest: #000a99;
  --colour-action-default: #0011ff;
  --colour-action-disabled: #c1c7cc;
  --colour-action-light-darken: #99a0ff;
  --colour-action-light-darkest: #6670ff;
  --colour-action-light-default: #cccfff;
  --colour-action-light-disabled: #c1c7cc;
  --colour-action-light-lighten: #e5e7ff;
  --colour-action-light-lightest: #ffffff;
  --colour-action-light-on-action-light-disabled: #3341ff;
  --colour-action-light-on-action-light: #0011ff;
  --colour-action-lighten: #3341ff;
  --colour-action-lightest: #e5e7ff;
  --colour-action-on-action-disabled: #3341ff;
  --colour-action-on-action: #ffffff;
  --colour-neutral-grey-05: #0d0d0d;
  --colour-neutral-grey-10: #222222;
  --colour-neutral-grey-20: #2f3037;
  --colour-neutral-grey-30: #464852;
  --colour-neutral-grey-35: #58595b;
  --colour-neutral-grey-45: #707275;
  --colour-neutral-grey-75: #bebfc0;
  --colour-neutral-grey-90: #e5e5e6;
  --colour-neutral-grey-95: #f4f4f5;
  --colour-neutral-grey-98: #fafafa;
  --colour-neutral-white: #ffffff;
  --colour-neutral-white-in-hex: 256, 256, 256;
  --colour-brand-darken: #00454d;
  --colour-brand-darkest: #002e33;
  --colour-brand-default: #008077;
  --colour-brand-default-or-gradient: #008077;
  --colour-brand-disabled: #151617;
  --colour-brand-lighten: #00b086;
  --colour-brand-lightest: #e5fff9;
  --colour-brand-on-brand-disabled: #00b086;
  --colour-brand-on-brand: #ffffff;
  --colour-error-darken: #990000;
  --colour-error-darkest: #660000;
  --colour-error-default: #cc0000;
  --colour-error-disabled: #c1c7cc;
  --colour-error-lighten: #ff002b;
  --colour-error-lightest: #ffe5e6;
  --colour-error-on-error-disabled: #ffffff;
  --colour-error-on-error: #ffffff;
  --colour-foreground-darken: #001e2b;
  --colour-foreground-darkest: #001219;
  --colour-foreground-default: #00344b;
  --colour-foreground-disabled: #c1c7cc;
  --colour-foreground-lighten: #184a68;
  --colour-foreground-lightest: #f3f4f5;
  --colour-foreground-muted: #456178;
  --colour-foreground-on-foreground-disabled: #456178;
  --colour-foreground-on-foreground: #ffffff;
  --colour-foreground-subtle: #e0e2e5;
  --colour-highlight-default: #ccfff3;
  --colour-highlight-on-highlight: #00344b;
  --colour-info-darken: #2e3e75;
  --colour-info-darkest: #222e58;
  --colour-info-default: #2541a7;
  --colour-info-disabled: #c1c7cc;
  --colour-info-lighten: #325bd4;
  --colour-info-lightest: #e8effc;
  --colour-info-on-info-disabled: #3341ff;
  --colour-info-on-info: #ffffff;
  --colour-success-darken: #00454d;
  --colour-success-darkest: #002e33;
  --colour-success-default: #008077;
  --colour-success-disabled: #c1c7cc;
  --colour-success-lighten: #00b086;
  --colour-success-lightest: #ddfbef;
  --colour-success-on-success-disabled: #00b086;
  --colour-success-on-success: #ffffff;
  --colour-warning-darken: #ccaa00;
  --colour-warning-darkest: #665500;
  --colour-warning-default: #ffd500;
  --colour-warning-disabled: #c1c7cc;
  --colour-warning-lighten: #ffdd33;
  --colour-warning-lightest: #fffbe5;
  --colour-warning-on-warning-disabled: #ccaa00;
  --colour-warning-on-warning: #00344b;
  --colour-hero-colour: #008077;
  --colour-hero-gradient-start: rgba(255 255 255 / 0.25);
  --colour-hero-gradient-end: rgba(217 217 217 / 0);
  --colour-promotions-start: rgba(255 255 255 / 0.25);
  --colour-promotions-end: rgba(217 217 217 / 0);
}
