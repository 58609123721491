:root[data-site='midlandCity'] {
  --colour-accent-1-darken: #17314f;
  --colour-accent-1-darkest: #0b1828;
  --colour-accent-1-default: #244c7a;
  --colour-accent-1-disabled: #e5e5e6;
  --colour-accent-1-lighten: #326aa9;
  --colour-accent-1-lightest: #d9e8fa;
  --colour-accent-1-on-accent-1-disabled: #e5e5e6;
  --colour-accent-1-on-accent-1: #ffffff;
  --colour-accent-2-darken: #6c0012;
  --colour-accent-2-darkest: #48000c;
  --colour-accent-2-default: #911522;
  --colour-accent-2-disabled: #e5e5e6;
  --colour-accent-2-lighten: #c21c2d;
  --colour-accent-2-lightest: #fbe9eb;
  --colour-accent-2-on-accent-2-disabled: #e5e5e6;
  --colour-accent-2-on-accent-2: #ffffff;
  --colour-action-darken: #17314f;
  --colour-action-darkest: #0b1828;
  --colour-action-default: #244c7a;
  --colour-action-disabled: #e5e5e6;
  --colour-action-light-darken: #b4d2f4;
  --colour-action-light-darkest: #79aeec;
  --colour-action-light-default: #d9e8fa;
  --colour-action-light-disabled: #e5e5e6;
  --colour-action-light-lighten: #ecf4fc;
  --colour-action-light-lightest: #ffffff;
  --colour-action-light-on-action-light-disabled: #b4d2f4;
  --colour-action-light-on-action-light: #244c7a;
  --colour-action-lighten: #326aa9;
  --colour-action-lightest: #ecf4fc;
  --colour-action-on-action-disabled: #e5e5e6;
  --colour-action-on-action: #ffffff;
  --colour-neutral-grey-05: #0d0d0d;
  --colour-neutral-grey-10: #222222;
  --colour-neutral-grey-20: #2f3037;
  --colour-neutral-grey-30: #464852;
  --colour-neutral-grey-35: #58595b;
  --colour-neutral-grey-45: #707275;
  --colour-neutral-grey-75: #bebfc0;
  --colour-neutral-grey-90: #e5e5e6;
  --colour-neutral-grey-95: #f4f4f5;
  --colour-neutral-grey-98: #fafafa;
  --colour-neutral-white: #ffffff;
  --colour-neutral-white-in-hex: 256, 256, 256;
  --colour-brand-darken: #0d0d0d;
  --colour-brand-darkest: #000000;
  --colour-brand-default: #222222;
  --colour-brand-default-or-gradient: #222222;
  --colour-brand-disabled: #e5e5e6;
  --colour-brand-lighten: #2f3037;
  --colour-brand-lightest: #fafafa;
  --colour-brand-on-brand-disabled: #e5e5e6;
  --colour-brand-on-brand: #ffffff;
  --colour-error-darken: #6c0012;
  --colour-error-darkest: #48000c;
  --colour-error-default: #911522;
  --colour-error-disabled: #e5e5e6;
  --colour-error-lighten: #c21c2d;
  --colour-error-lightest: #fbe9eb;
  --colour-error-on-error-disabled: #e5e5e6;
  --colour-error-on-error: #ffffff;
  --colour-foreground-darken: #222222;
  --colour-foreground-darkest: #0d0d0d;
  --colour-foreground-default: #2f3037;
  --colour-foreground-disabled: #e5e5e6;
  --colour-foreground-lighten: #464852;
  --colour-foreground-lightest: #fafafa;
  --colour-foreground-muted: #707275;
  --colour-foreground-on-foreground-disabled: #e5e5e6;
  --colour-foreground-on-foreground: #ffffff;
  --colour-foreground-subtle: #e5e5e6;
  --colour-highlight-default: #c9e5e5;
  --colour-highlight-on-highlight: #2f3037;
  --colour-info-darken: #17314f;
  --colour-info-darkest: #0b1828;
  --colour-info-default: #244c7a;
  --colour-info-disabled: #e5e5e6;
  --colour-info-lighten: #326aa9;
  --colour-info-lightest: #d9e8fa;
  --colour-info-on-info-disabled: #e5e5e6;
  --colour-info-on-info: #ffffff;
  --colour-success-darken: #153226;
  --colour-success-darkest: #08120e;
  --colour-success-default: #245642;
  --colour-success-disabled: #e5e5e6;
  --colour-success-lighten: #18794e;
  --colour-success-lightest: #ecf8f0;
  --colour-success-on-success-disabled: #e5e5e6;
  --colour-success-on-success: #ffffff;
  --colour-warning-darken: #a97613;
  --colour-warning-darkest: #5b400b;
  --colour-warning-default: #e29e1a;
  --colour-warning-disabled: #e5e5e6;
  --colour-warning-lighten: #ffba37;
  --colour-warning-lightest: #fff6e5;
  --colour-warning-on-warning-disabled: #e5e5e6;
  --colour-warning-on-warning: #2f3037;
  --colour-hero-colour: #222222;
  --colour-hero-gradient-start: rgba(255 255 255 / 0.25);
  --colour-hero-gradient-end: rgba(217 217 217 / 0);
  --colour-promotions-start: rgba(255 255 255 / 0.25);
  --colour-promotions-end: rgba(217 217 217 / 0);
}
