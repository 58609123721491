:root[data-site='vicParkCity'] {
  --colour-foreground-darkest: #171923;
  --colour-foreground-darken: #1a202c;
  --colour-foreground-default: #2d3748;
  --colour-foreground-lighten: #4a5568;
  --colour-foreground-muted: #718096;
  --colour-foreground-subtle: #e2e8f0;
  --colour-foreground-lightest: #f7fafc;
  --colour-foreground-on-foreground: #ffffff;
  --colour-foreground-disabled: #cbd5e0;
  --colour-foreground-on-foreground-disabled: #456178;
  --colour-brand-darkest: #000000;
  --colour-brand-darken: #0f0f0f;
  --colour-brand-default: #212121;
  --colour-brand-default-or-gradient: #212121;
  --colour-brand-lighten: #e0e0e0;
  --colour-brand-lightest: #f5f5f5;
  --colour-brand-on-brand: #ffffff;
  --colour-brand-disabled: #9e9e9e;
  --colour-brand-on-brand-disabled: #424242;
  --colour-action-darkest: #000000;
  --colour-action-darken: #0f0f0f;
  --colour-action-default: #212121;
  --colour-action-lighten: #e0e0e0;
  --colour-action-lightest: #f5f5f5;
  --colour-action-on-action: #fafafa;
  --colour-action-disabled: #9e9e9e;
  --colour-action-on-success: #fafafa;
  --colour-action-on-action-disabled: #424242;
  --colour-info-darkest: #1a365d;
  --colour-info-darken: #2a4365;
  --colour-info-default: #2c5282;
  --colour-info-lighten: #2b6cb0;
  --colour-info-lightest: #e5e5e5;
  --colour-info-on-info: #f6f6f6;
  --colour-info-disabled: #cbd5e0;
  --colour-info-on-info-disabled: #2b6cb0;
  --colour-success-darkest: #1c4532;
  --colour-success-darken: #22543d;
  --colour-success-default: #276749;
  --colour-success-lighten: #25855a;
  --colour-success-lightest: #f0fff4;
  --colour-success-on-success: #ffffff;
  --colour-success-disabled: #cbd5e0;
  --colour-success-on-success-disabled: #25855a;
  --colour-warning-darkest: #5b400b;
  --colour-warning-darken: #a97613;
  --colour-warning-default: #e29e1a;
  --colour-warning-lighten: #ffba37;
  --colour-warning-lightest: #fff6e5;
  --colour-warning-on-warning: #2d3748;
  --colour-warning-disabled: #cbd5e0;
  --colour-warning-on-warning-disabled: #a97613;
  --colour-error-darkest: #4f212c;
  --colour-error-darken: #773243;
  --colour-error-default: #8f3d51;
  --colour-error-lighten: #b34c65;
  --colour-error-lightest: #f7edef;
  --colour-error-on-error: #ffffff;
  --colour-error-disabled: #cbd5e0;
  --colour-error-on-error-disabled: #c27084;
  --colour-highlight-default: #b2f5ea;
  --colour-highlight-on-highlight: #2d3748;
  --colour-accent-1-darkest: #902c73;
  --colour-accent-1-darken: #a33383;
  --colour-accent-1-default: #b83280;
  --colour-accent-1-lighten: #f59bcf;
  --colour-accent-1-lightest: #fbe7f3;
  --colour-accent-1-on-accent-1: #902c73;
  --colour-accent-1-disabled: #884971;
  --colour-accent-1-on-accent-1-disabled: #843868;
  --colour-accent-2-darkest: #4f212c;
  --colour-accent-2-darken: #773243;
  --colour-accent-2-default: #8f3d51;
  --colour-accent-2-lighten: #b34c65;
  --colour-accent-2-on-accent-2: #ffffff;
  --colour-accent-2-lightest: #eed7dc;
  --colour-accent-2-disabled: #cbd5e0;
  --colour-accent-2-on-error: #ffffff;
  --colour-accent-2-on-accent-2-disabled: #c27084;
  --colour-neutral-grey-80: #0f363d;
  --colour-neutral-grey-50: #656567;
  --colour-neutral-grey-45: #707275;
  --colour-neutral-grey-60: #98989a;
  --colour-neutral-grey-20: #2f3037;
  --colour-neutral-grey-90: #e5e5e6;
  --colour-neutral-grey-95: #f4f4f5;
  --colour-neutral-grey-98: #fafafa;
  --colour-neutral-grey-75: #bebfc0;
  --colour-neutral-grey-35: #58595b;
  --colour-neutral-grey-30: #464852;
  --colour-neutral-grey-10: #222222;
  --colour-neutral-grey-05: #0d0d0d;
  --colour-neutral-black: #000000;
  --colour-neutral-white: #ffffff;
  --colour-neutral-white-in-hex: 256, 256, 256;
  --colour-action-light-darkest: #e66a77;
  --colour-action-light-darken: #ec8892;
  --colour-action-light-default: #f1a7ae;
  --colour-action-light-lighten: #f8d3d7;
  --colour-action-light-lightest: #fbe9eb;
  --colour-action-light-on-action-light: #8f191c;
  --colour-action-light-on-action-light-disabled: #ec8892;
  --colour-action-light-disabled: #e5e5e6;
  --colour-hero-colour: #000000;
  --colour-hero-gradient-start: rgba(255 255 255 / 0);
  --colour-hero-gradient-end: rgba(217 217 217 / 0);
  --colour-promotions-start: rgba(255 255 255 / 0.25);
  --colour-promotions-end: rgba(217 217 217 / 0);
}
