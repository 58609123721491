@use 'theme';
@use 'responsive';
@use 'designSystem';

$resultSize: 20px;

.react-autosuggest__container {
  position: relative;
  display: flex;
  flex-grow: 1;

  @include designSystem.textStyleBodySmall16;
}

.react-autosuggest__input {
  padding: 20px;
  border-radius: 24px;
  border: 1px solid designSystem.$colorTertiaryPageBg;
  font-style: normal;
  font-weight: normal;
  width: 100%;
  height: 48px;
  padding-left: 48px;
}

.react-autosuggest__input--open {
  border-radius: 4px 4px 0 0;
  border-bottom: none;
}

.react-autosuggest__suggestions-container {
  display: none;
  border-radius: 0 0 4px 4px;
  border: 2px solid designSystem.$colorDivider;
  border-top: none;
  background-color: transparent;
  text-align: left;
}

.react-autosuggest__suggestions-container--open {
  background-color: white;
  display: block;
  position: absolute;
  top: 48px;
  padding: 0;
  width: 100%;
  max-height: 500px;
  overflow-y: auto;

  @include responsive.ltDesktop {
    background-color: white;
  }

  > div {
    color: white;
    font-size: 1.57rem;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 1.5;
    text-align: left;
    display: block;
    position: relative;
    padding: 15px 25px 15px 20px;

    @include responsive.ltDesktop {
      font-size: 1.15rem;
      padding: 10px;
    }

    @include responsive.mobile {
      padding: 10px 0;
    }

    a {
      color: black;
      font-size: 1.57rem;
      line-height: 1.5;
      text-decoration: underline;
      display: inline-block;

      @include responsive.ltDesktop {
        font-size: 1.15rem;
      }
    }
  }
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0 0 6px 15px;
  list-style-type: none;

  @include responsive.ltDesktop {
    padding: 0;
  }
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 0;

  p {
    color: designSystem.$colorSecondaryMidGrey;
    margin: 0;
    padding: 10px 0 10px 15px;
    border-radius: 10px;

    strong {
      color: designSystem.$colorPrimaryDark;
    }

    @include responsive.ltDesktop {
      padding: 10px;
    }

    @include responsive.mobile {
      padding-left: 0;
      padding-right: 0;
    }

    &:nth-child(1) {
      font-size: 1.15rem;
      padding-bottom: 0;
    }

    &:nth-child(2) {
      font-style: italic;
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 10px;

      @include responsive.ltDesktop {
        padding-bottom: 10px;
      }
    }

    &:hover {
      background-color: designSystem.$colorSecondaryLightLightBlue;
    }

    ::before {
      @include theme.apply(color, action-default);
    }

    p::before {
      background-color: red;
    }
  }

  a {
    color: white;
    text-decoration: none;
    display: block;

    &:hover {
      color: white;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}

.react-autosuggest__suggestion--highlighted {
  background-color: white;

  p {
    color: black;
  }
}

.react-autosuggest__section-title {
  @include designSystem.textStyleHeadingSmall16;

  color: designSystem.$colorPrimaryDark;
}
