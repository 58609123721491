@font-face {
  font-family: 'Avenir Next W01';
  src: url('/fonts/2cd55546-ec00-4af9-aeca-4a3cd186da53.woff2') format('woff2'),
    url('/fonts/1e9892c0-6927-4412-9874-1b82801ba47a.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next W01';
  src: url('/fonts/aad99a1f-7917-4dd6-bbb5-b07cedbff64f.woff2') format('woff2'),
    url('/fonts/91b50bbb-9aa1-4d54-9159-ec6f19d14a7c.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next W01';
  src: url('/fonts/14c73713-e4df-4dba-933b-057feeac8dd1.woff2') format('woff2'),
    url('/fonts/b8e906a1-f5e8-4bf1-8e80-82c646ca4d5f.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: lato;
  src: url('/fonts/lato-regular.woff2') format('woff2'), url('/fonts/lato-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: lato;
  src: url('/fonts/lato-bold.woff2') format('woff2'), url('/fonts/lato-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: lato;
  src: url('/fonts/lato-italic.woff2') format('woff2'), url('/fonts/lato-italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Kabel';
  src: url('/fonts/NeueKabel-Regular.woff2') format('woff2'), url('/fonts/NeueKabel-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Kabel';
  src: url('/fonts/NeueKabel-Bold.woff2') format('woff2'), url('/fonts/NeueKabel-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Kabel';
  src: url('/fonts/NeueKabel-Italic.woff2') format('woff2'), url('/fonts/NeueKabel-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
