:root[data-site='perthCarBuying'] {
  --colour-foreground-darkest: #0d0d0d;
  --colour-foreground-darken: #222222;
  --colour-foreground-default: #2f3037;
  --colour-foreground-lighten: #464852;
  --colour-foreground-muted: #707275;
  --colour-foreground-subtle: #e5e5e6;
  --colour-foreground-lightest: #fafafa;
  --colour-foreground-on-foreground: #ffffff;
  --colour-foreground-disabled: #e5e5e6;
  --colour-foreground-on-foreground-disabled: #464852;
  --colour-brand-darkest: #1d1d20;
  --colour-brand-darken: #27272a;
  --colour-brand-default: #313135;
  --colour-brand-default-or-gradient: #313135;
  --colour-brand-lighten: #464649;
  --colour-brand-lightest: #d6d6d7;
  --colour-brand-on-brand: #ffffff;
  --colour-brand-disabled: #e5e5e6;
  --colour-brand-on-brand-disabled: #464649;
  --colour-action-darkest: #1c0a64;
  --colour-action-darken: #260e85;
  --colour-action-default: #2f11a6;
  --colour-action-lighten: #8270ca;
  --colour-action-lightest: #d5cfed;
  --colour-action-on-action: #ffffff;
  --colour-action-disabled: #e5e5e6;
  --colour-action-on-success: #ffffff;
  --colour-action-on-action-disabled: #5a5a5d;
  --colour-info-darkest: #1d1d20;
  --colour-info-darken: #27272a;
  --colour-info-default: #2c2c30;
  --colour-info-lighten: #313135;
  --colour-info-lightest: #d6d6d7;
  --colour-info-on-info: #ffffff;
  --colour-info-disabled: #e5e5e6;
  --colour-info-on-info-disabled: #464649;
  --colour-success-darkest: #181409;
  --colour-success-darken: #312912;
  --colour-success-default: #625125;
  --colour-success-lighten: #8c7336;
  --colour-success-lightest: #fefaef;
  --colour-success-on-success: #ffffff;
  --colour-success-disabled: #e5e5e6;
  --colour-success-on-success-disabled: #8c7336;
  --colour-warning-darkest: #5b400b;
  --colour-warning-darken: #a97613;
  --colour-warning-default: #e29e1a;
  --colour-warning-lighten: #ffba37;
  --colour-warning-lightest: #fff6e5;
  --colour-warning-on-warning: #2f3037;
  --colour-warning-disabled: #e5e5e6;
  --colour-warning-on-warning-disabled: #a97613;
  --colour-error-darkest: #611b05;
  --colour-error-darken: #912808;
  --colour-error-default: #dc3a04;
  --colour-error-lighten: #f9470e;
  --colour-error-lightest: #feeeea;
  --colour-error-on-error: #ffffff;
  --colour-error-disabled: #e5e5e6;
  --colour-error-on-error-disabled: #fa6538;
  --colour-highlight-default: #fae5ae;
  --colour-highlight-on-highlight: #2f3037;
  --colour-accent-1-darkest: #312912;
  --colour-accent-1-darken: #625125;
  --colour-accent-1-default: #8c7336;
  --colour-accent-1-lighten: #c4a24a;
  --colour-accent-1-lightest: #fae5ae;
  --colour-accent-1-on-accent-1: #ffffff;
  --colour-accent-1-disabled: #e5e5e6;
  --colour-accent-1-on-accent-1-disabled: #ddb753;
  --colour-accent-2-darkest: #260e85;
  --colour-accent-2-darken: #2f11a6;
  --colour-accent-2-default: #4429af;
  --colour-accent-2-lighten: #5941b8;
  --colour-accent-2-on-accent-2: #ffffff;
  --colour-accent-2-lightest: #d5cfed;
  --colour-accent-2-disabled: #e5e5e6;
  --colour-accent-2-on-error: #ffffff;
  --colour-accent-2-on-accent-2-disabled: #6d58c1;
  --colour-neutral-grey-80: #0f363d;
  --colour-neutral-grey-50: #656567;
  --colour-neutral-grey-45: #707275;
  --colour-neutral-grey-60: #98989a;
  --colour-neutral-grey-90: #e5e5e6;
  --colour-neutral-grey-95: #f4f4f5;
  --colour-neutral-grey-98: #fafafa;
  --colour-neutral-grey-75: #bebfc0;
  --colour-neutral-grey-35: #58595b;
  --colour-neutral-grey-30: #464852;
  --colour-neutral-grey-20: #2f3037;
  --colour-neutral-grey-10: #222222;
  --colour-neutral-grey-05: #0d0d0d;
  --colour-neutral-black: #000000;
  --colour-neutral-white: #ffffff;
  --colour-neutral-white-in-hex: 256, 256, 256;
  --colour-action-light-darkest: #f5cb5c;
  --colour-action-light-darken: #f6d06c;
  --colour-action-light-default: #f8db8d;
  --colour-action-light-lighten: #fae5ae;
  --colour-action-light-lightest: #fefaef;
  --colour-action-light-on-action-light: #2f11a6;
  --colour-action-light-on-action-light-disabled: #f6d06c;
  --colour-action-light-disabled: #e5e5e6;
  --colour-hero-colour: #313135;
  --colour-hero-gradient-start: rgba(255 255 255 / 0.25);
  --colour-hero-gradient-end: rgba(217 217 217 / 0);
  --colour-promotions-start: rgba(255 255 255 / 0.25);
  --colour-promotions-end: rgba(217 217 217 / 0);
}
