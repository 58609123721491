@use 'responsive';
@use 'designSystem';
@use 'node_modules/react-multi-carousel/lib/styles.css';

.react-multi-carousel-list {
  .react-multiple-carousel__arrow {
    transition: none;
  }

  .react-multiple-carousel__arrow--left {
    background-color: designSystem.$colorSecondaryLightLightBlue;

    &::before {
      border-radius: 50%;
      background-color: transparent;
      background-position: center;
      background-size: 8px;
      background-repeat: no-repeat;
      background-image: url('~images/icons/chevron--forward.svg');
      color: transparent;
      transform: rotateY(-180deg);

      @include responsive.mobile {
        width: 36px;
        height: 36px;
      }
    }
  }

  .react-multiple-carousel__arrow--right {
    background-color: designSystem.$colorSecondaryLightLightBlue;

    &::before {
      border-radius: 50%;
      background-color: transparent;
      background-position: center;
      background-size: 8px;
      background-repeat: no-repeat;
      background-image: url('~images/icons/chevron--forward.svg');
      color: transparent;

      @include responsive.mobile {
        width: 36px;
        height: 36px;
      }
    }
  }
}
