@use 'theme';

.Toastify__toast--error {
  @include theme.apply(color, foreground-on-foreground);
}

.toast-fade-in--top-center {
  animation: toastFadeIn 0.5s ease both;
}

.toast-fade-in--bottom-center {
  animation: toastFadeInDown 0.5s ease both;
}

.toast-fade-out--top-center {
  animation: toastFadeOut 0.5s ease both;
}

.toast-fade-out--bottom-center {
  animation: toastFadeOutDown 0.5s ease both;
}

@keyframes toastFadeIn {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes toastFadeInDown {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes toastFadeOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-100px);
    opacity: 0;
  }
}

@keyframes toastFadeOutDown {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(100px);
    opacity: 0;
  }
}