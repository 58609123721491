:root[data-site='carco'] {
  --colour-foreground-darkest: #0d0d0d;
  --colour-foreground-darken: #222222;
  --colour-foreground-default: #2f3037;
  --colour-foreground-lighten: #464852;
  --colour-foreground-muted: #707275;
  --colour-foreground-subtle: #e5e5e6;
  --colour-foreground-lightest: #fafafa;
  --colour-foreground-on-foreground: #ffffff;
  --colour-foreground-disabled: #e5e5e6;
  --colour-foreground-on-foreground-disabled: #464852;
  --colour-brand-darkest: #912808;
  --colour-brand-darken: #f9470e;
  --colour-brand-default: #fa6538;
  --colour-brand-default-or-gradient: #fa6538;
  --colour-brand-lighten: #fb8e6a;
  --colour-brand-lightest: #feeeea;
  --colour-brand-on-brand: #ffffff;
  --colour-brand-disabled: #e5e5e6;
  --colour-brand-on-brand-disabled: #fb8e6a;
  --colour-action-darkest: #021a15;
  --colour-action-darken: #05342a;
  --colour-action-default: #084f3f;
  --colour-action-lighten: #0d775f;
  --colour-action-lightest: #eff6f4;
  --colour-action-on-action: #ffffff;
  --colour-action-disabled: #e5e5e6;
  --colour-action-on-success: #ffffff;
  --colour-action-on-action-disabled: #0d775f;
  --colour-info-darkest: #182630;
  --colour-info-darken: #1a2b35;
  --colour-info-default: #35576a;
  --colour-info-lighten: #50839f;
  --colour-info-lightest: #e1f2fb;
  --colour-info-on-info: #ffffff;
  --colour-info-disabled: #cbcbcd;
  --colour-info-on-info-disabled: #50839f;
  --colour-success-darkest: #021a15;
  --colour-success-darken: #05342a;
  --colour-success-default: #084f3f;
  --colour-success-lighten: #0d775f;
  --colour-success-lightest: #eff6f4;
  --colour-success-on-success: #ffffff;
  --colour-success-disabled: #e5e5e6;
  --colour-success-on-success-disabled: #0d775f;
  --colour-warning-darkest: #5b400b;
  --colour-warning-darken: #a97613;
  --colour-warning-default: #e29e1a;
  --colour-warning-lighten: #ffba37;
  --colour-warning-lightest: #fff6e5;
  --colour-warning-on-warning: #2f3037;
  --colour-warning-disabled: #e5e5e6;
  --colour-warning-on-warning-disabled: #a97613;
  --colour-error-darkest: #4f212c;
  --colour-error-darken: #773243;
  --colour-error-default: #8f3d51;
  --colour-error-lighten: #b34c65;
  --colour-error-lightest: #f7edef;
  --colour-error-on-error: #ffffff;
  --colour-error-disabled: #e5e5e6;
  --colour-error-on-error-disabled: #c27084;
  --colour-highlight-default: #dfece9;
  --colour-highlight-on-highlight: #2f3037;
  --colour-accent-1-darkest: #182630;
  --colour-accent-1-darken: #1a2b35;
  --colour-accent-1-default: #35576a;
  --colour-accent-1-lighten: #50839f;
  --colour-accent-1-lightest: #e1f2fb;
  --colour-accent-1-on-accent-1: #ffffff;
  --colour-accent-1-disabled: #e5e5e6;
  --colour-accent-1-on-accent-1-disabled: #008ccc;
  --colour-accent-2-darkest: #4f212c;
  --colour-accent-2-darken: #773243;
  --colour-accent-2-default: #8f3d51;
  --colour-accent-2-lighten: #b34c65;
  --colour-accent-2-on-accent-2: #ffffff;
  --colour-accent-2-lightest: #eed7dc;
  --colour-accent-2-disabled: #e5e5e6;
  --colour-accent-2-on-error: #ffffff;
  --colour-accent-2-on-accent-2-disabled: #c27084;
  --colour-neutral-grey-80: #0f363d;
  --colour-neutral-grey-50: #656567;
  --colour-neutral-grey-45: #707275;
  --colour-neutral-grey-60: #98989a;
  --colour-neutral-grey-20: #2f3037;
  --colour-neutral-grey-90: #e5e5e6;
  --colour-neutral-grey-95: #f4f4f5;
  --colour-neutral-grey-98: #fafafa;
  --colour-neutral-grey-75: #bebfc0;
  --colour-neutral-grey-35: #58595b;
  --colour-neutral-grey-30: #464852;
  --colour-neutral-grey-10: #222222;
  --colour-neutral-grey-05: #0d0d0d;
  --colour-neutral-black: #000000;
  --colour-neutral-white: #ffffff;
  --colour-neutral-white-in-hex: 256, 256, 256;
  --colour-action-light-darkest: #7db5a8;
  --colour-action-light-darken: #9dc8be;
  --colour-action-light-default: #c9e0db;
  --colour-action-light-lighten: #dfece9;
  --colour-action-light-lightest: #eff6f4;
  --colour-action-light-on-action-light: #084f3f;
  --colour-action-light-on-action-light-disabled: #9dc8be;
  --colour-action-light-disabled: #e5e5e6;
  --colour-hero-colour: #fa6538;
  --colour-hero-gradient-start: rgba(255 255 255 / 0.25);
  --colour-hero-gradient-end: rgba(217 217 217 / 0);
  --colour-promotions-start: rgba(255 255 255 / 0.25);
  --colour-promotions-end: rgba(217 217 217 / 0);
}
