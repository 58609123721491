@use 'const';
@use 'theme';
@use 'designSystem';
@use 'mixins';
@use 'responsive';
@use 'vendor.scss';
@use 'sites/sites';

* {
  font-family: const.$font;
  box-sizing: border-box;
}

html {
  background-color: var(--colour-neutral-grey-95);
  font-size: 14px;
  overflow-x: hidden;

  > body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    header {
      transition-duration: 0.15s;

      &.slideUp {
        @include responsive.mobile {
          transform: translateY(-(const.$headerHeightMobile));
        }
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: unset;
}

*,
button,
input,
optgroup,
select,
textarea {
  font-family: const.$font;
}

button {
  cursor: pointer;
}

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none;
}

em {
  padding-right: 5px;
  padding-left: 5px;
  font-style: normal;
}

label {
  display: inline-block;

  &[disabled] {
    cursor: not-allowed;
  }
}

select {
  &[disabled],
  &:disabled {
    cursor: not-allowed;
  }
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
}

input[type='text'],
input[type='email'],
input[type='tel'],
input[type='number'] {
  &[data-valid='false'] {
    @include theme.apply(border-color, error-default);

    &:focus {
      outline: none;
      box-shadow: 0 0 10px var(--colour-error-default);

      @include theme.apply(border-color, error-default);
    }
  }
}

.ReactModal__Overlay.ReactModal__Overlay {
  background-color: rgba(designSystem.$colorPrimaryDark, 0.6) !important; // stylelint-disable-line
  z-index: 101;

  > div {
    border: none;
  }
}

.termsModalParent {
  margin: auto;
  height: 100%;
  overflow: hidden !important; // stylelint-disable-line
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: const.$margin * 0.5;

  @include responsive.mobile {
    inset: const.$margin !important; // stylelint-disable-line
  }
}

.termsModal {
  width: 100%;
  height: 100%;
  position: relative;
  max-height: 500px;
  padding: 36px;
  border-radius: 30px;
  max-width: 600px;

  @include theme.apply(background-color, neutral-white);

  > div {
    overflow: auto;
    height: 100%;
  }

  p {
    @include theme.apply(color, foreground-default);
    @include designSystem.textStyleBodyMedium22;
  }

  button[data-type='close'] {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    cursor: pointer;
    padding: 0;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    @include theme.apply(background-color, action-lightest);
    @include theme.apply(color, action-darken);
    @include mixins.svgColor(action-darken);

    &:hover {
      @include theme.apply(background-color, action-darken);
      @include theme.apply(color, action-on-action);
      @include mixins.svgColor(action-on-action);
    }
  }
}

.ReactModal__Body--open,
body[no-scroll='true'] {
  max-height: 100vh;
  height: 100vh;
  overflow-y: hidden;

  // Copied from https://github.com/postcss/postcss-100vh-fix
  @supports (-webkit-touch-callout: none) {
    /* The hack for Safari */
    height: -webkit-fill-available; // stylelint-disable value-no-vendor-prefix
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: textfield;
}

/* react-quick-pinch-zoom for vdp gallery */
.kvfysmfp.kvfysmfp.kvfysmfp {
  overflow: visible;
  position: relative;
  height: 100%;
}

// * This is used with <p> tags to mock heading styles via CMS redactor field
// * helpful as this allows another component to used the h1 for seo
.fake-heading.fake-heading.fake-heading.fake-heading.fake-heading {
  @include responsive.ltXlDesktop {
    @include designSystem.textStyleHeadingTitle54;

    br {
      display: none;
    }
  }

  @include responsive.ltLargeDesktop {
    @include designSystem.textStyleHeadingLarge42;
  }

  @include responsive.tablet {
    @include designSystem.textStyleHeadingLarge36;
  }

  @include responsive.mobile {
    font-size: 2.57rem;
  }

  @include designSystem.textStyleHeadingTitle54;
}

// responsive data attributes

[data-show-only='desktop'] {
  @include responsive.ltDesktop {
    display: none !important; // stylelint-disable-line
  }
}

[data-show-only='tablet'] {
  @include responsive.ltMobile {
    display: none !important; // stylelint-disable-line
  }

  @include responsive.desktop {
    display: none !important; // stylelint-disable-line
  }
}

[data-show-only='mobile'] {
  @include responsive.gtMobile {
    display: none !important; // stylelint-disable-line
  }
}

[data-hide-only='desktop'] {
  @include responsive.desktop {
    display: none !important; // stylelint-disable-line
  }
}

[data-hide-only='tablet'] {
  @include responsive.gtMobile {
    display: none !important; // stylelint-disable-line
  }

  @include responsive.ltDesktop {
    display: none !important; // stylelint-disable-line
  }
}

[data-hide-only='mobile'] {
  @include responsive.ltMobile {
    display: none !important; // stylelint-disable-line
  }
}

[data-show-only='vdp-desktop'] {
  @include responsive.vdpMobileLayout {
    display: none !important; // stylelint-disable-line
  }
}

[data-show-only='vdp-mobile'] {
  @include responsive.vdpDesktopLayout {
    display: none !important; // stylelint-disable-line
  }
}

[data-show-only='vdp-increase-mobile'] {
  @include responsive.vdpSizeDecreaseMobile {
    display: none !important; // stylelint-disable-line
  }
}

[data-show-only='vdp-decrease-mobile'] {
  @include responsive.vdpSizeIncreaseMobile {
    display: none !important; // stylelint-disable-line
  }
}
