@use 'const';
@use 'designSystem';
@use 'responsive';
@use 'theme';

.keen-slider {
  max-height: 100%;
  height: 100%;
  width: 100%;

  &.keen-slider-headline {
    background: none;
    border-radius: 0;
    height: 158px;

    @include responsive.ltDesktop {
      height: 118px;
    }

    @include responsive.mobile {
      height: 98px;
    }

    @media (max-width: 640px) {
      height: 128px;
    }

    @include responsive.smallPhone {
      height: 92px;
    }

    .keen-slider__slide {
      @include theme.apply('color', neutral-white);
      @include designSystem.styleHeadline;

      position: relative;
      text-align: center;

      @include responsive.smallPhone {
        height: 128px;
      }

      @include responsive.smallPhone {
        height: 92px;
      }
    }
  }

  &.keen-slider-headline-small {
    background: none;
    border-radius: 0;
    height: 100px;

    @include responsive.ltDesktop {
      height: 80px;
    }

    @include responsive.mobile {
      height: 66px;
    }

    @include responsive.smallPhone {
      height: 100px;
    }

    .keen-slider__slide {
      @include theme.apply('color', neutral-white);
      @include designSystem.styleHeadlineSmall;

      position: relative;
      text-align: left;

      @include responsive.ltDesktop {
        text-align: center;
      }
    }
  }

  &.keen-slider-headline-with-border {
    .keen-slider__slide {
      padding-left: 40px;

      @include responsive.ltDesktop {
        padding-left: 0;
      }
    }
  }

  &.keen-slider-carousel {
    border-radius: 0;

    .fader__slide {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;

      img {
        background-color: transparent;
        border-radius: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        max-height: 100%;

        @include responsive.ltDesktop {
          border-radius: 0;
        }
      }
    }
  }

  &.keen-slider-landing-carousel {
    border-radius: 0;

    .fader__slide {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;

      > picture {
        position: relative;
        width: 100%;
        height: 100%;

        img {
          background-color: transparent;
          border-radius: 0;
          object-fit: contain;
          object-position: center;
          max-width: 100%;
          max-height: 100%;

          @include responsive.ltDesktop {
            border-radius: 0;
          }
        }
      }
    }
  }

  &.keen-slider-pills {
    background: none;
    border-radius: 0;
    height: 24px;

    @include responsive.desktop {
      display: none !important; // stylelint-disable-line
    }
  }

  .keen-slider__slide {
    min-width: 100%;

    img {
      background-color: transparent;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &.keen-slider-pill__slide {
      @include theme.apply(color, neutral-white);
      @include designSystem.textStyleBodySmall18;

      display: flex;
      flex-direction: row;
      gap: 8px;

      @include responsive.ltDesktop {
        justify-content: center;
      }
    }
  }
}
