:root[data-site='carplace'] {
  --colour-foreground-darkest: #001c22;
  --colour-foreground-darken: #00303a;
  --colour-foreground-default: #004352;
  --colour-foreground-lighten: #336975;
  --colour-foreground-muted: #54818b;
  --colour-foreground-subtle: #ccd9dc;
  --colour-foreground-lightest: #f0f5f7;
  --colour-foreground-on-foreground: #ffffff;
  --colour-foreground-disabled: #d1d3cf;
  --colour-foreground-on-foreground-disabled: #336975;
  --colour-brand-darkest: #002730;
  --colour-brand-darken: #004352;
  --colour-brand-default: #005e73;
  --colour-brand-default-or-gradient: linear-gradient(90deg, #005e73 0.04%, #009e71 99.96%);
  --colour-brand-lighten: #2c7a7b;
  --colour-brand-lightest: #e6eff1;
  --colour-brand-on-brand: #ffffff;
  --colour-brand-disabled: #d1d3cf;
  --colour-brand-on-brand-disabled: #2c7a7b;
  --colour-action-darkest: #0f363d;
  --colour-action-darken: #144852;
  --colour-action-default: #1e6d7b;
  --colour-action-lighten: #2891a4;
  --colour-action-lightest: #d6f0f5;
  --colour-action-on-action: #ffffff;
  --colour-action-disabled: #d1d3cf;
  --colour-action-on-success: #ffffff;
  --colour-action-on-action-disabled: #2891a4;
  --colour-info-darkest: #3e585f;
  --colour-info-darken: #6894a1;
  --colour-info-default: #93d1e3;
  --colour-info-lighten: #a9dae9;
  --colour-info-lightest: #f4fafc;
  --colour-info-on-info: #ffffff;
  --colour-info-disabled: #d1d3cf;
  --colour-info-on-info-disabled: #6894a1;
  --colour-success-darkest: #00422f;
  --colour-success-darken: #007050;
  --colour-success-default: #00805c;
  --colour-success-lighten: #33b18d;
  --colour-success-lightest: #f0fff4;
  --colour-success-on-success: #ffffff;
  --colour-success-disabled: #d1d3cf;
  --colour-success-on-success-disabled: #33b18d;
  --colour-warning-darkest: #664c0d;
  --colour-warning-darken: #ad8116;
  --colour-warning-default: #f3b61f;
  --colour-warning-lighten: #f5c54c;
  --colour-warning-lightest: #fffff0;
  --colour-warning-on-warning: #004352;
  --colour-warning-disabled: #d1d3cf;
  --colour-warning-on-warning-disabled: #ad8116;
  --colour-error-darkest: #420000;
  --colour-error-darken: #700000;
  --colour-error-default: #9e0000;
  --colour-error-lighten: #b13333;
  --colour-error-lightest: #f5e6e6;
  --colour-error-on-error: #ffffff;
  --colour-error-disabled: #d1d3cf;
  --colour-error-on-error-disabled: #b13333;
  --colour-highlight-default: #ccff62;
  --colour-highlight-on-highlight: #004352;
  --colour-accent-1-darkest: #4b6b08;
  --colour-accent-1-darken: #7fb50e;
  --colour-accent-1-default: #b3ff14;
  --colour-accent-1-lighten: #c2ff43;
  --colour-accent-1-lightest: #f7ffe8;
  --colour-accent-1-on-accent-1: #004352;
  --colour-accent-1-disabled: #d1d3cf;
  --colour-accent-1-on-accent-1-disabled: #628c0b;
  --colour-accent-2-darkest: #4a0a47;
  --colour-accent-2-darken: #7d1079;
  --colour-accent-2-default: #b017aa;
  --colour-accent-2-lighten: #c045bb;
  --colour-accent-2-on-accent-2: #004352;
  --colour-accent-2-lightest: #f7e8f7;
  --colour-accent-2-disabled: #d1d3cf;
  --colour-accent-2-on-error: #ffffff;
  --colour-accent-2-on-accent-2-disabled: #7d1079;
  --colour-neutral-grey-80: #0f363d;
  --colour-neutral-grey-50: #656567;
  --colour-neutral-grey-45: #7e7e81;
  --colour-neutral-grey-60: #98989a;
  --colour-neutral-grey-20: #cbcbcd;
  --colour-neutral-grey-90: #e5e5e6;
  --colour-neutral-grey-95: #f2f2f2;
  --colour-neutral-grey-98: #fafafa;
  --colour-neutral-white: #ffffff;
  --colour-neutral-white-in-hex: 256, 256, 256;
  --colour-action-light-darkest: #009e71;
  --colour-action-light-darken: #54bea0;
  --colour-action-light-default: #b0e1d3;
  --colour-action-light-lighten: #e6f5f1;
  --colour-action-light-lightest: #f5fbf9;
  --colour-action-light-on-action-light: #00422f;
  --colour-action-light-on-action-light-disabled: #d1d3cf;
  --colour-action-light-disabled: #009e71;
  --colour-hero-colour: #039672;
  --colour-hero-gradient-start: rgba(255 255 255 / 0.25);
  --colour-hero-gradient-end: rgba(217 217 217 / 0);
  --colour-promotions-start: rgba(255 255 255 / 0.25);
  --colour-promotions-end: rgba(217 217 217 / 0);
}
