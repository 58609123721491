:root[data-site='berwickCity'] {
  --colour-accent-1-darken: #d69e2e;
  --colour-accent-1-darkest: #b7791f;
  --colour-accent-1-default: #ecc94b;
  --colour-accent-1-disabled: #f6e05e;
  --colour-accent-1-lighten: #f6e05e;
  --colour-accent-1-lightest: #fffff0;
  --colour-accent-1-on-accent-1-disabled: #f6e05e;
  --colour-accent-1-on-accent-1: #2d3748;
  --colour-accent-2-darken: #822727;
  --colour-accent-2-darkest: #63171b;
  --colour-accent-2-default: #9b2c2c;
  --colour-accent-2-disabled: #cbd5e0;
  --colour-accent-2-lighten: #c53030;
  --colour-accent-2-lightest: #fff5f5;
  --colour-accent-2-on-accent-2-disabled: #c53030;
  --colour-accent-2-on-accent-2: #ffffff;
  --colour-action-darken: #2a4365;
  --colour-action-darkest: #1a365d;
  --colour-action-default: #2c5282;
  --colour-action-disabled: #cbd5e0;
  --colour-action-light-darken: #63b3ed;
  --colour-action-light-darkest: #4299e1;
  --colour-action-light-default: #90cdf4;
  --colour-action-light-disabled: #cbd5e0;
  --colour-action-light-lighten: #bee3f8;
  --colour-action-light-lightest: #ebf8ff;
  --colour-action-light-on-action-light-disabled: #63b3ed;
  --colour-action-light-on-action-light: #2c5282;
  --colour-action-lighten: #2b6cb0;
  --colour-action-lightest: #ebf8ff;
  --colour-action-on-action-disabled: #2b6cb0;
  --colour-action-on-action: #ffffff;
  --colour-neutral-grey-05: #0d0d0d;
  --colour-neutral-grey-10: #222222;
  --colour-neutral-grey-20: #2f3037;
  --colour-neutral-grey-30: #464852;
  --colour-neutral-grey-35: #58595b;
  --colour-neutral-grey-45: #707275;
  --colour-neutral-grey-75: #bebfc0;
  --colour-neutral-grey-90: #e5e5e6;
  --colour-neutral-grey-95: #f4f4f5;
  --colour-neutral-grey-98: #fafafa;
  --colour-neutral-white: #ffffff;
  --colour-neutral-white-in-hex: 256, 256, 256;
  --colour-brand-darken: #044468;
  --colour-brand-darkest: #003959;
  --colour-brand-default: #00597f;
  --colour-brand-default-or-gradient: #00597f;
  --colour-brand-disabled: #cbd5e0;
  --colour-brand-lighten: #0078ab;
  --colour-brand-lightest: #e1f5fd;
  --colour-brand-on-brand-disabled: #0078ab;
  --colour-brand-on-brand: #ffffff;
  --colour-error-darken: #6c0012;
  --colour-error-darkest: #48000c;
  --colour-error-default: #911522;
  --colour-error-disabled: #e5e5e6;
  --colour-error-lighten: #c21c2d;
  --colour-error-lightest: #fbe9eb;
  --colour-error-on-error-disabled: #e5e5e6;
  --colour-error-on-error: #ffffff;
  --colour-foreground-darken: #1a202c;
  --colour-foreground-darkest: #171923;
  --colour-foreground-default: #2d3748;
  --colour-foreground-disabled: #cbd5e0;
  --colour-foreground-lighten: #4a5568;
  --colour-foreground-lightest: #f7fafc;
  --colour-foreground-muted: #718096;
  --colour-foreground-on-foreground-disabled: #456178;
  --colour-foreground-on-foreground: #ffffff;
  --colour-foreground-subtle: #e2e8f0;
  --colour-highlight-default: #b2f5ea;
  --colour-highlight-on-highlight: #2d3748;
  --colour-info-darken: #2a4365;
  --colour-info-darkest: #1a365d;
  --colour-info-default: #2c5282;
  --colour-info-disabled: #cbd5e0;
  --colour-info-lighten: #2b6cb0;
  --colour-info-lightest: #ebf8ff;
  --colour-info-on-info-disabled: #2b6cb0;
  --colour-info-on-info: #ffffff;
  --colour-success-darken: #22543d;
  --colour-success-darkest: #1c4532;
  --colour-success-default: #276749;
  --colour-success-disabled: #cbd5e0;
  --colour-success-lighten: #25855a;
  --colour-success-lightest: #f0fff4;
  --colour-success-on-success-disabled: #25855a;
  --colour-success-on-success: #ffffff;
  --colour-warning-darken: #a97613;
  --colour-warning-darkest: #5b400b;
  --colour-warning-default: #e29e1a;
  --colour-warning-disabled: #e5e5e6;
  --colour-warning-lighten: #ffba37;
  --colour-warning-lightest: #fff6e5;
  --colour-warning-on-warning-disabled: #e5e5e6;
  --colour-warning-on-warning: #2f3037;
  --colour-hero-colour: #222222;
  --colour-hero-gradient-start: rgba(255 255 255 / 0);
  --colour-hero-gradient-end: rgba(217 217 217 / 0);
  --colour-promotions-start: rgba(255 255 255 / 0.25);
  --colour-promotions-end: rgba(217 217 217 / 0);
}
